<template>
  <div class="container">
        <div class="col-sm-8 offset-sm-2">
            <div class="row">
                <div class="col-sm-12">
                    <h1 class="mt-4 text-blue">Liste des abonnés</h1>

                    <table class="table mt-3 table-bordered table-hover">
                        <thead>
                        <tr>
                            <th>Abonnés</th>
                            <th>Inscription</th>
                        </tr>
                        </thead>
                        <tbody>
                                <tr v-for="item in data" :key="item.id">
                                    <td>{{item.email}}</td>
                                    <td>{{item.created_at}}</td>
                                </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import backendService from '../../../backend.service';
export default {
    data(){
        return {
            data : []
        }
    },
    mounted(){
        backendService.newsletters_get().then((response)=>{
                    this.data = response.data.data;
        }).catch(()=>{})
    }
}
</script>

<style>

</style>