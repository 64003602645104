<template>
        <div class="h-full d-flex">
            <div class="h-full-w400px">
                <div class="h-full-h85 p-4">
                        <p class="text-blue font-weight-bold">Ajouter une nouvelle offre d'emploi</p>
                        <input type="text" v-model="title" placeholder="Titre" class="mt-3 form-control">
                        <textarea type="text" v-model="description" placeholder="Description" class="mt-3 form-control"></textarea>
                        <input type="date" v-model="validate" placeholder="" class="mt-3 mb-3 form-control">
                        <textarea id="editorhtml" class="mt-3"></textarea>                        
                </div>
                <div class="h-full-h15 border-top p-2">
                    <button @click="create" :disabled="!title || !description || !validate" type="button" class="btn btn-md w-100 btn-site font-weight-bold">Publier
                         <div v-if="loading" class="spinner-border text-light float-right" style="height:20px;width:20px" role="status">
                                    <span class="sr-only">Loading...</span>
                         </div>
                    </button>
                </div>
            </div>
            <div class="h-full-rw400px border-left p-3">
                    <h1 class="text-blue">Liste des offres d'emploi</h1>
                     <table class="table mt-3 table-bordered table-hover">
                        <thead>
                        <tr>
                            <th>Titre</th>
                            <th>Description</th>
                            <th>Validité</th>
                            <th>Publié</th>
                            <th>Candidats</th>
                        </tr>
                        </thead>
                        <tbody>
                                <tr v-for="item in offer" :key="item.id">
                                    <td class="align-middle">
                                        {{item.title}}
                                    </td>
                                    <td class="align-middle">
                                        {{item.description}}
                                    </td>
                                    <td class="align-middle">
                                        {{item.validate}}
                                    </td>
                                    <td class="align-middle">
                                        {{item.created_at}}
                                    </td>
                                    <td class="align-middle">
                                        <a class="btn btn-site font-weight-bold">Voir la liste</a>
                                    </td>
                                </tr>
                        </tbody>
                    </table>
            </div>
        </div>
</template>

<script>
import $ from 'jquery';
import backendService from '../../../backend.service'
export default {
    data(){
        return {
            loading : false,
            offer : [],
            title : null,
            description : null,
            validate : null,
        }
    },
    mounted(){
            backendService.job_get().then((response)=>{
                    this.offer = response.data.data;
            }).catch(()=>{});

            $('#editorhtml').summernote({
                placeholder: 'Saisissez ...',
                tabsize: 2,
                height: 200,
                toolbar: [
                    ['style', ['style']],
                    ['font', ['fontname','bold', 'underline', 'clear','strikethrough', 'superscript', 'subscript']],
                    ['color', ['color']],
                    ['fontsize', ['fontsize']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['table', ['table']],
                    ['insert', ['link', 'picture','hr']],
                    ['view', ['fullscreen','codeview']]
                ]
            });
    },
    methods : {
        create(){
            this.loading = true;
            backendService.job_create({
                title : this.title,
                content : $('#editorhtml'). summernote('code'),
                validate : this.validate,
                description : this.description
            }).then((response)=>{   
                    this.loading = false;
                    this.offer = response.data.data;
            }).catch(()=>{
                this.loading = false;
            })
        }
    }
}
</script>

<style>

</style>