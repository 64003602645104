<template>
   <div style="font-size:16px">
      <h4 class="text-blue"><b>Nos Collaborateurs</b></h4>
      <p>
         Nos collaborateurs constituent la première force et le pilier important de notre entreprise.
      </p>
      <p>
         Un accent particulier est mis sur la formation continue de nos employés afin de vous fournir un service de qualité dans le seul but de vous satisfaire au mieux.
      </p>
      <h4 class="text-blue mt-5"><b>Disponibilité du produit</b></h4>
      <p><b>Chez DISTRICOM, c’est zéro rupture.</b> Nous disposons d’une capacité de stockage importante et d’une flotte automobile conséquente pour assurer l’approvisionnement continu de nos points de vente de gaz et de nos stations-services.</p>
      <p>
         Les stations-services DISTRICOM sont ouvertes 24h/24 et 7j/7 pour vous servir à tout moment avec un personnel qualifié, toujours prêt à vous conseiller dans vos choix.
      </p>
      <h4 class="text-blue mt-5"><b>Implantation</b></h4>
      <p>Notre implantation sur le territoire national est matérialisée par un important réseau de stations-services et de plusieurs points de vente de gaz en constante croissance.</p>
      <p>Nous sommes présents à Abidjan et dans toutes les grandes villes de l’intérieur du pays.</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>