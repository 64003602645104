<template>
    <div class="mt-5 container">
        <div class="col-sm-12 text-center">
            <div class="row">
                <div class="col-sm-12 mb-3">
                    <h1>Chiffres clés</h1>
                    <p>Découvrez DISTRICOM en quelques chiffres</p>
                </div>
                <div class="col-sm-12 estimation" id="estimation-hidden" style="display:none">
                    <div>
                       <div class="d-flex mb-3 justify-content-around">
                            <div class="mb-2">
                                <p><font-awesome-icon class="icon-estimation" style="color:#FFFF00" icon="pump-soap"/></p>
                                <p class="title-p mb-0">
                                    02
                                </p>
                                <p class="m-0 font-weight-bold">Centre <br> emplisseur <br>  GAZ</p>
                            </div>
                            <div class="mb-2">
                                <p><font-awesome-icon class="icon-estimation" style="color:#FFFF00" icon="gas-pump"/></p>
                                <p class="title-p mb-0">
                                    17
                                </p>
                                <p class="m-0 font-weight-bold">Stations</p>
                            </div>
                       </div>
                       <div class="d-flex mb-3 justify-content-around">
                            <div >
                                <p><font-awesome-icon class="icon-estimation" style="color:#FFFF00" icon="store-alt"/></p>
                                <p class="title-p mb-0">
                                    600+
                                </p>
                                <p class="m-0 font-weight-bold">Point <br> de vente <br> de GAZ</p>
                            </div>
                            <div>
                                <p><font-awesome-icon class="icon-estimation" style="color:#FFFF00" icon="people-carry"/></p>
                                <p class="title-p mb-0">
                                    400+
                                </p>
                                <p class="m-0 font-weight-bold">Collaborateurs</p>
                            </div>
                       </div>
                       <div class="d-flex mb-3 justify-content-around">
                            <div>
                                <p><font-awesome-icon class="icon-estimation" style="font-size:80px;color:#FFFF00" icon="truck-moving"/></p>
                                <p class="title-p mb-0">
                                    30+
                                </p>
                                <p class="m-0 font-weight-bold font-weight-bold">Flotte automobile</p>
                            </div>
                        </div>
                        <!-- <div >
                            <p class="title-p">
                                1M+
                            </p>
                            <p class="m-0">Capacité de stockage</p>
                        </div> -->
                    </div>
                </div>
                <div class="col-sm-12 estimation" id="estimation-showed">
                    <div class="d-flex justify-content-around">
                        <div>
                            <p><font-awesome-icon style="font-size:80px;color:#FFFF00" icon="pump-soap"/></p>
                            <p class="title-p">
                                02
                            </p>
                            <p class="m-0 font-weight-bold">Centre emplisseur GAZ</p>
                        </div>
                        <div >
                             <p><font-awesome-icon style="font-size:80px;color:#FFFF00" icon="gas-pump"/></p>
                            <p class="title-p">
                                17
                            </p>
                            <p class="m-0 font-weight-bold">Stations</p>
                        </div>
                        <div>
                            <p><font-awesome-icon style="font-size:80px;color:#FFFF00" icon="store-alt"/></p>
                            <p class="title-p">
                                600+
                            </p>
                            <p class="m-0 font-weight-bold">Point de vente de GAZ</p>
                        </div>
                        <div>
                            <p><font-awesome-icon style="font-size:80px;color:#FFFF00" icon="people-carry"/></p>
                            <p class="title-p">
                                400+
                            </p>
                            <p class="m-0 font-weight-bold">Collaborateurs</p>
                        </div>
                        <div >
                            <p><font-awesome-icon style="font-size:80px;color:#FFFF00" icon="truck-moving"/></p>
                            <p class="title-p">
                                30+
                            </p>
                            <p class="m-0 font-weight-bold">Flotte automobile</p>
                        </div>
                        <!-- <div >
                            <p class="title-p">
                                1M+
                            </p>
                            <p class="m-0">Capacité de stockage</p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'Estimation',
  mixins : [],
  props: {}
}
</script>

<style scoped>
    .estimation{
        background: #fff;
        min-height:100px;
        color:#000;
    }

     .estimation .title-p{
            font-weight: 900;
            font-size: 3rem;
            color:#007DFF;
     }
</style>