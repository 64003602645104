import axios from 'axios'
import BackendRouteLink from './BackendRouteLink'
const backendService = {

    signin(data){
        return axios.post('https://apidistrico.districom-ci.net/public/signin',data);
    },      

    station_create(data){
        return axios.post(BackendRouteLink.station_create,data)
    },

    station_get(){
        return axios.get(BackendRouteLink.station_get)
    },
    gaz_create(data){
        return axios.post(BackendRouteLink.gaz_create,data)
    },
    gaz_delete(id){
        return axios.delete(BackendRouteLink.gaz_delete+"/"+id)
    },
    gaz_get(){
        return axios.get(BackendRouteLink.gaz_get)
    },
    station_search(city){
        return axios.get(BackendRouteLink.station_search+"/"+city)
    },
    newsletters(data){
        return axios.post(BackendRouteLink.newsletter_add,data)
    },
    newsletters_get(){
        return axios.get(BackendRouteLink.newsletter_get)
    },
    news_create(data){
        return axios.post(BackendRouteLink.news_create,data)
    },
    news_get(){
        return axios.get(BackendRouteLink.news_get)
    },
    job_create(data){
        return axios.post(BackendRouteLink.job_create,data)
    },
    job_get(){
        return axios.get(BackendRouteLink.job_get)
    },
    job_public_get(id){
        if(id)
        return axios.get(BackendRouteLink.job_public_get+'/'+id)
        else
        return axios.get(BackendRouteLink.job_public_get)
    },
    conseil_public_get(id){
        if(id)
        return axios.get(BackendRouteLink.conseil_get+'/'+id)
        else
        return axios.get(BackendRouteLink.conseil_get)
    },
    conseil_getsimular(id){
        return axios.get(BackendRouteLink.conseil_getsimular+'/'+id)
    },
    conseil_create(data){
        return axios.post(BackendRouteLink.conseil_create,data)
    },
    conseil_delete(id){
        return axios.delete(BackendRouteLink.conseil_delete+'/'+id)
    },
    actualite_public_get(id){
        if(id)
        return axios.get(BackendRouteLink.actualite_get+'/'+id)
        else
        return axios.get(BackendRouteLink.actualite_get)
    },
    actualite_getsimular(id){
        return axios.get(BackendRouteLink.actualite_getsimular+'/'+id)
    },
    actualite_create(data){
        return axios.post(BackendRouteLink.actualite_create,data)
    },
    actualite_delete(id){
        return axios.delete(BackendRouteLink.actualite_delete+'/'+id)
    },
    actualite_postcomment(data){
        return axios.post(BackendRouteLink.actualite_postcomment,data)
    },
    actualite_getcomment(id){
        return axios.get(BackendRouteLink.actualite_getcomment+'/'+id)
    },
    postcomment(data){
        return axios.post(BackendRouteLink.conseil_postcomment,data)
    },
    getcomment(id){
        return axios.get(BackendRouteLink.conseil_getcomment+'/'+id)
    },
    application_sp(data){
        return axios.post(BackendRouteLink.application_sp,data)
    },
    applicationsp_get(){
        return axios.get(BackendRouteLink.applicationsp_get)
    },
    applicationoffer_create(data){
        return axios.post(BackendRouteLink.applicationoffer_create,data)
    },
    contactinfo(data){
        return axios.post(BackendRouteLink.contactinfo,data)
    }

};
export default backendService;