<template>
  <div style="background: #eee">
    <div
      class="container pb-5 p-0"
      style="font-size: 14px; text-align: justify"
    >
      <div class="col-sm-12">
        <div class="row">
          <div
            class="col-sm-12 align-middle text-center p-5 prestation-title-page"
            style="background: #ffff00; height: 350px; font-size: 20px"
          >
            <!-- <h4>NOS PRODUITS ET SERVICES DISPONIBLES <br> DANS TOUTES LES STATIONS DE LA
            COMPAGNIE POUR LES PROFESSIONNELS.</h4> -->
          </div>

          <div class="col-sm-12" style="margin-top: -300px">
            <img
              src="../../../assets/Site-Districom-service-particuliers.jpg"
              class="img-fluid"
              alt=""
            />
            <div class="col-sm-12 mt-3 p-3">
              <p>
                <span class="font-weight-bold p-2" style="background: #ffff00"
                  >Nos produits</span
                >
              </p>
              <p class="font-weight-bold text-blue h6">1. Carburants</p>
              <div class="col-sm-12 shadow-sm bg-white p-5">
                <div class="row">
                  <div class="col-sm-3 mb-3">
                    <img
                      class="img-fluid"
                      src="../../../assets/24-7-service-.png"
                      alt=""
                    />
                  </div>
                  <div class="col-sm-9 mb-3">
                    <p>
                      Soucieux de répondre aux besoins des clients, DISTRICOM
                      propose des produits et services de qualité. Les produits
                      Carburants (super sans plomb, gasoil etc…) sont
                      disponibles pour les clients dans nos stations-service,
                      présente sur toute l’étendue du territoire ivoirien.
                      Toutes nos stations-services sont ouvertes 24h/24 et 7j/7.
                      Notre capacité de stockage accrue et notre fréquence de
                      rotation nous permettent de ne jamais être en rupture.
                    </p>
                    <p>
                      Nous vous fournissons du carburant de qualité, dont les
                      spécifications sont conformes aux normes internationales.
                    </p>
                    <p>
                      Nous démontrons continuellement notre professionnalisme et
                      assurons la qualité de nos services à travers notre
                      important réseau de stations-services.
                    </p>
                  </div>
                </div>
              </div>
              <p class="font-weight-bold mt-5 text-blue h6">2. Lubrifiants</p>
              <div class="col-sm-12 shadow-sm bg-white p-5">
                <div class="row">
                  <div class="col-sm-3 mb-3">
                    <img
                      class="img-fluid"
                      src="../../../assets/IMG_5110.jpg"
                      alt=""
                    />
                  </div>
                  <div class="col-sm-9 mb-3">
                    <p>
                      <b>DISTRICOM</b> s’engage à répondre à tous vos besoins en
                      matière de lubrifiants et à vous apporter le support
                      technique et commercial nécessaire. Nous offrons une gamme
                      complète de lubrifiants destinés aux automobilistes, aux
                      industriels, ainsi que des huiles et graisse à usage
                      spécial pour diverses utilisations. Les produits que nous
                      commercialisons sont en conformité avec les cahiers des
                      charges les plus exigeants des constructeurs automobiles
                      et sont disponibles à travers notre réseau de
                      stations-services.
                    </p>
                  </div>
                </div>
              </div>
              <p class="font-weight-bold mt-5 text-blue h6">3. Gaz butane</p>

              <div class="col-sm-12 shadow-sm bg-white p-5">
                <div class="row">
                  <div class="col-sm-3 mb-3">
                    <img
                      class="img-fluid"
                      src="../../../assets/Gaz.jpg"
                      alt=""
                    />
                  </div>
                  <div class="col-sm-9 mb-3">
                    <p>
                      Nous distribuons du gaz butane pour les ménages et
                      commerces sous trois (3) formats :
                    </p>
                    <p>
                      <b>- La bouteille B6 :</b> La bouteille économique pour
                      les usages occasionnels
                    </p>
                    <p>
                      <b>- La bouteille B12 :</b> La bouteille B12 : La
                      bouteille économique aux usages multiples pour une
                      consommation moyenne
                    </p>
                    <p>
                      - <b>La bouteille B38 : </b> La bouteille B38 : La
                      bouteille pour les consommations importantes et les
                      utilisations professionnelles
                    </p>
                  </div>
                </div>
              </div>

              <p class="mt-5">
                <span class="font-weight-bold p-2" style="background: #ffff00"
                  >Nos services</span
                >
              </p>
              <p>
                Les stations-services DISTRICOM OIL proposent plusieurs services
                à sa clientèle,
              </p>
              <div class="col-sm-12 shadow-sm bg-white p-5">
                <p class="font-weight-bold text-blue h6">
                  Services d’entretien
                </p>
                <div class="row">
                  <div class="col-sm-3 mb-3">
                    <img
                      class="img-fluid"
                      src="../../../assets/LAVAGE.png"
                      alt=""
                    />
                  </div>
                  <div class="col-sm-9 mb-3">
                    <ul>
                      <li>Lavage</li>
                      <li>Vidange</li>
                      <li>Graissage</li>
                      <li>Nettoyage de parebrise</li>
                      <li>Vérification du niveau d’huile</li>
                      <li>Vérification de la pression des pneus.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 mt-5 shadow-sm bg-white p-5">
                <p class="font-weight-bold text-blue h6">Superettes</p>
                <div class="row">
                  <div class="col-sm-3 mb-3">
                    <img
                      class="img-fluid"
                      src="../../../assets/Superettes.jpg"
                      alt=""
                    />
                  </div>
                  <div class="col-sm-9 mb-3">
                    <p>
                      Les stations DISTRICOM OIL vous proposent des superettes
                      et supermarchés où vous trouverez une large gamme de
                      produits de grande consommation (hygiène, alimentaires,
                      cosmétiques, …) dans un cadre agréable. Nous faisons en
                      sorte que la qualité et la diversité des produits proposés
                      dans nos boutiques répondent aux attentes de la clientèle.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Swiper,
  Navigation,
  Pagination,
  Autoplay,
  Scrollbar,
  A11y,
} from "swiper";
import "swiper/swiper-bundle.css";
Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, A11y]);
export default {
  mounted() {
    setTimeout(() => {
      new Swiper(".swiper-container.stations", {
        // Optional parameters
        autoplay: {
          delay: 5000,
        },
        speed: 500,
        direction: "horizontal",
        loop: true,
        // If we need pagination
        pagination: {
          el: ".swiper-pagination",
        },

        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // And if we need scrollbar
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      });
    }, 2000);
  },
};
</script>

<style scoped lang="scss">
</style>