<template>
  <div style="background:#fff">
      <!-- <div class="box-img-flyer">
        <img src="../assets/slider/bg_04.jpg"  alt="">
        <div class="absolute-title text-white">
          <div class="pb-3" style="width:100%;height:100%;">
                <p>Nos stations !</p>
          </div>
        </div>
      </div> -->

      <div class="col-sm-12">
        <div class="row">
      <div class="col-sm-4 p-0">
        <div class="col-sm-12 p-0">
          <img src="../assets/slider/bg_04.jpg" class="img-fluid"  alt="">
        </div>
        <div class="col-sm-12 mt-3">
            <h2 style="font-size:16px" class="m-0 text-blue font-weight-bold">Retrouvez toutes nos stations</h2>
            <p> Nous sommes implantés dans <b>{{cities.city.length}}</b> villes de la Côte d'Ivoire, avec plus de <b>{{cities.list.length}}</b> stations ...</p>
            <v-select @input="search" v-model="city" class="style-chooser mt-3" placeholder="Selectionner une ville" :options="citieslist"></v-select>
            <div v-if="loading" class="d-flex  mt-3 justify-content-between">
              <span>Patientez svp ...</span>
              <div class="spinner-border text-yellow text-center" role="status">
                    <span class="sr-only">Loading...</span>
              </div>
            </div>

            <div class="col-sm-12 shadow-sm mt-4 p-2" style="border:2px solid #FFFF00" v-for="item in searchvalues" :key="item.id">
                <h6 class="text-blue mt-2 font-weight-bold">{{item.name}}</h6>
                <p>Ville : {{item.ville}}</p>
                <p class="m-0"><span class="text-blue">Localisation</span> : {{item.location}}</p>
                <p class="m-0"><span class="text-blue">Contacts</span> : {{item.tel}} / {{item.email}} </p>
                <p class="m-0"><span class="text-blue">Ouverture</span> : {{item.hour}} </p>
                <p class="m-0"><span class="text-blue">Services disponible</span> : {{item.services}} </p>
            </div>
        </div> 
      </div>

      <div class="col-sm-8 p-0">
           <GmapMap
              :options="{
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                disableDefaultUi: false
              }"
              :center="{
                lat: 6.7960670,
                lng: -5.2794310
              }"
               style="width: 100%; height: 800px"
                :zoom="7"
              >

               <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="toggleInfoWindow(m,index)"
                :icon="markerOptions"
              />
              <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
              </gmap-info-window>
            </GmapMap>
    </div>
            </div>
      </div>
  </div>
</template>

<script>
import { Swiper, Navigation, Pagination,Autoplay, Scrollbar,A11y  } from 'swiper';
import 'swiper/swiper-bundle.css';
import backendService from '../backend.service';
Swiper.use([Navigation, Pagination, Autoplay,Scrollbar,A11y ]);
const mapMarker = require('../assets/clipart2350691.png');
export default {
  data(){
    return {
      cities : {},
      citieslist : [],
      loading : false,
      city : null,
      searchvalues : [],
      markers : [{
        position : {
          lat: 6.7960670,
          lng: -5.2794310
        }
      }],
      markerOptions: {
        url: mapMarker,
        size: {width: 50, height: 60, f: 'px', b: 'px',},
        scaledSize: {width: 30, height: 45, f: 'px', b: 'px',},
      },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: '',
        pixelOffset: {
            width: 0,
            height: -35
        }
      },
    }
  },
  mounted(){
      setTimeout(()=>{
          new Swiper('.swiper-container.stations', {
              // Optional parameters
              autoplay: {
                  delay: 5000,
              },
              speed: 500,
              direction: 'horizontal',
              loop: true,
              // If we need pagination
              pagination: {
                  el: '.swiper-pagination',
              },

              // Navigation arrows
              navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
              },

              // And if we need scrollbar
              scrollbar: {
                  el: '.swiper-scrollbar',
              },
          });
    },2000);

    backendService.station_get().then((response)=>{
        this.cities = response.data.data;
        response.data.data.city.forEach(element => {
            this.citieslist.push(element.ville);
        });
    });

    if(this.$route.params.city)
      this.city = this.$route.params.city,
      this.search();
  },
  methods : {
    search : function(){
        this.loading = true;
        backendService.station_search(this.city).then((response)=>{
            this.loading = false;
            this.searchvalues =  response.data.data;
            this.markers = [];
            this.searchvalues.forEach(element => {
                this.markers.push({
                  position : {
                    lat : parseFloat(element.lat),
                    lng : parseFloat(element.lon),
                  },
                  details : element
                })
            });
        });
    },
    toggleInfoWindow: function(marker, idx) {
            this.infoWindowPos = marker.position;
            this.infoOptions.content = '<h4 class="text-blue">'+marker.details.name+'</h4>'
            +'<p>Ville : '+marker.details.ville+'</p>'
            +'<p>Localisation  : '+marker.details.ville+'</p>'
            +'<p>Contacts : '+marker.details.tel+' / '+marker.details.email+'</p>'
            +'<p>Ouverture  : '+marker.details.hour+'</p>'
            +'<p>Services disponible  : '+marker.details.services+'</p>';
            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == idx) {
              this.infoWinOpen = !this.infoWinOpen;
            }
            //if different marker set infowindow to open and reset current marker index
            else {
              this.infoWinOpen = true;
              this.currentMidx = idx;
            }
    }
  }
}
</script>

<style scoped lang="scss">
    
    .swiper-container {
      width: 100%;
      height: 300px;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;

      img{
        width:100%;
        height:100%;
        object-fit: cover;
      }

    }
</style>