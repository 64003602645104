<template>
  <div class="container">
        <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-12">
                    <h3 class="mt-4 text-blue">Liste des candidatures spontanées</h3>

                    <table class="table mt-3 table-bordered table-hover">
                        <thead>
                        <tr>
                            <th>Postulant</th>
                            <th>Poste</th>
                            <th>Téléphone</th>
                            <th>Email</th>
                            <th>Message</th>
                            <th>CV</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                                <tr v-for="item in data" :key="item.id">
                                    <td class="align-middle">{{item.name}}</td>
                                    <td class="align-middle">{{item.post}}</td>
                                    <td class="align-middle">{{item.tel}}</td>
                                    <td class="align-middle">{{item.email}}</td>
                                    <td class="align-middle">{{item.message}}</td>
                                    <td style="width:100px" class="align-middle"><a target="_blank" :href="item.attached">Voir le cv</a></td>
                                    <td class="align-middle">{{item.created_at}}</td>
                                </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import backendService from '../../../backend.service';
export default {
    data(){
        return {
            data : []
        }
    },
    mounted(){
        backendService.applicationsp_get().then((response)=>{
                    this.data = response.data.data;
        }).catch(()=>{})
    }
}
</script>

<style>

</style>