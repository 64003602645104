<template>
  <div class="col-sm-12 mb-5" style="margin-top:150px;" v-if="data">
    <div class="row">
        <div class="col-sm-2">
            <ul class="list-group" v-if="data && data.conseil_title" style="margin-top:150px;cursor : pointer">
                <li class="list-group-item font-weight-bold" style="font-size:12px;background:#fff">Partager cet article</li>
                <li class="list-group-item">
                    <ShareNetwork
                        network="facebook"
                        :url="'https://districom.ci'+$route.path"
                        :title="data.conseil_title"
                        :description="data.conseil_title"
                        :quote="data.conseil_title"
                    >
                    <img src="../../assets/facebook-f.png" style="width:20px" alt=""> Facebook
                    </ShareNetwork>
                </li>
                <li class="list-group-item">
                    <ShareNetwork
                        network="twitter"
                        :url="'https://districom.ci'+$route.path"
                        :title="data.conseil_title"
                    >
                    <img src="../../assets/twitter.png" style="width:20px" alt=""> Twitter
                    </ShareNetwork>
                </li>   
                <li class="list-group-item">
                    <ShareNetwork
                        network="linkedin"
                        :url="'https://districom.ci'+$route.path"
                    >
                    <img src="../../assets/logo-linkedin-294x294.png" style="width:20px" alt=""> LinkedIn
                    </ShareNetwork>
                </li>   
                <li class="list-group-item">
                    <ShareNetwork
                        network="whatsapp"
                        :url="'https://districom.ci'+$route.path"
                        :title="data.conseil_title"
                        :description="data.conseil_title"
                    >
                    <img src="../../assets/whatsapp.png" style="width:20px" alt=""> Whatsapp
                    </ShareNetwork>
                </li> 
                <li class="list-group-item">
                    <ShareNetwork
                        network="email"
                        :url="'https://districom.ci'+$route.path"
                        :title="data.conseil_title"
                        :description="data.conseil_title"
                    >
                    <img src="../../assets/email.png" style="width:20px" alt=""> Email
                    </ShareNetwork>
                </li>   
            </ul>
        </div>
        <div class="col-sm-8">
            <div class="alert alert-success" v-if="article">
                <div class="d-flex">
                    <div class="spinner-border mr-5" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                    <span>Patientez pendant le chargement de l'article ...</span>
                </div>
            </div>
            <div class="alert alert-danger" v-if="articlebug">
                Nous n'arrivons pas à nous connecter au serveur, merci de verifier votre connexion internet .. 
            </div>
            <div v-if="(!article && !articlebug) || (data && data.name)">
            <h1 class="text-orange mb-3">{{data.conseil_title}}</h1>
            <p class="m-0">Publié le : {{data.created_at}}</p>
            <div class="coverpicture mt-5" style="height:400px;width:100%">
                <img style="object-fit : cover;height:100%; width:100%" :src="'https://apidistrico.districom-ci.net/public/picture/show/'+data.conseil_image" alt="">
            </div>
            <div v-html="data.conseil_content" class="mt-5"></div>

            <div class="col-sm-12 mt-3 pl-0" v-if="simular.length > 0">
                <h4 class="text-orange"> Autres articles</h4>
                <div class="row">
                    <div class="col-sm-4 mb-3" v-for="(item) in simular" :key="item.conseil_id">
                        <div class="cadre border p-0">
                            <div style="width:100%;height:200px">
                                <img style="object-fit:cover;width:100%;height:100%" :src="'https://apidistrico.districom-ci.net/public/picture/show/'+item.conseil_image" alt="">
                            </div>                            <p class="m-0 p-3">{{item.conseil_title}}</p>
                            <p class="border-bottom m-0 p-2">
                                <button @click="urlreplace(item)" class="btn btn-site font-weight-bold btn-sm">Lire l'article</button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 mt-5" v-if="comment.length > 0">
                <div class="media mt-3" v-for="(cm) in comment" :key="cm.postc_id">
                    <img src="../../assets/usernoavatar.png" style="height:40px" class="align-self-start mr-3" alt="...">
                    <div class="media-body">
                        <h5 class="mt-0">{{cm.conseilc_user_name}} <br> <span style="font-size:12px" class="pull-right"><i>{{cm.created_at}}</i></span></h5>
                        <p>{{cm.conseilc_content}}</p>
                    </div>
                </div>
             </div>

            <div class="col-sm-12 mt-5 p-3 border" style="background:#F1F2F3">
                <h4 class="text-orange">Laissez un commentaire.</h4>
                <p>Nous seront ravis de savoir vos impressions concernant nos articles</p>
                <div v-if="!commenter">
                    <input type="email" v-model="postc_user_email" class="form-control" placeholder="Adresse email">
                    <input type="text" v-model="postc_user_name" class="form-control mt-3" placeholder="Nom complet">
                </div>
                <div v-if="commenter" class="media">
                    <img src="../../assets/usernoavatar.png" style="height:40px" class="align-self-start mr-3" alt="...">
                    <div class="media-body">
                        <h5 class="mt-0">{{commenter.postc_user_name}}</h5>
                        <p>{{commenter.postc_user_email}}</p>
                    </div>
                </div>
                <textarea name="" v-model="postc_content" id="" cols="30" rows="5" class="form-control mt-3" placeholder="Laissez votre commmentaire ici ..."></textarea>
                <div v-if="!commenter" class="custom-control custom-checkbox mt-3" style="font-size:12px">
                    <input v-model="savedata" type="checkbox" class="custom-control-input" id="customCheck1">
                    <label class="custom-control-label" for="customCheck1">Voulez vous que nous enregistrons vos informations dans le navigateur, pour ne plus avoir à saisir votre email et votre nom</label>
                </div>
                <button @click="postcomment" :disabled="!commenter && (!postc_user_email || !postc_user_name) || !postc_content || loading" class="btn btn-site mt-3 font-weight-bold">Commentez
                    <div v-if="loading" class="spinner-border text-light float-right mt-2" style="height:20px;width:20px" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </button>

               
             </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import backendService from './../../backend.service'
export default {
    data(){
      return {
          data : {},
          simular : [],
          postc_user_email : null,
          postc_user_name : null,
          postc_content : null,
          savedata : false,
          article : false,
          articlebug : false,
          comment : [],
          loading : false,
          commenter : JSON.parse(localStorage.getItem('districosite__comment__Oauth')),
      }
    },
    created(){
    },
    mounted(){
        this.only();
    },
    updated(){
       
    },
    computed : {    
        watchUrlChange(){
            return this.$route.path;
        }
    },
    methods : {
        only(){
                this.article = true;
                this.articlebug = false;
                backendService.conseil_public_get(this.$route.params.id).then((response)=>{
                    if(response.status == 200){
                        this.data = response.data.data;
                        document.title = this.data.conseil_title;
                        document.querySelector('meta[property="og:description"]').setAttribute('content',this.data.conseil_content)
                        document.querySelector('meta[property="og:url"]').setAttribute('content','https://districom.ci'+this.$route.path)
                        document.querySelector('meta[property="og:title"]').setAttribute('content',this.data.conseil_title)
                        document.querySelector('meta[property="og:image"]').setAttribute('content',"https://apidistrico.districom-ci.net/public/picture/show/"+this.data.conseil_image)
                        this.article = false;
                        this.articlebug = false;
                    }
                }).catch(()=>{
                    this.article = false;
                    this.articlebug = true;
                });

                 backendService.conseil_getsimular(this.$route.params.id).then((response)=>{
                    if(response.status == 200){
                        this.simular = response.data.data;
                    }
                });

                this.getcomment();
        },
        whatsapp(){
            window.location="https://wa.me/?text="+encodeURIComponent(window.location.href);
        },
        replace(info){
            let r = info.replace(/ /g,'-');
            r = info.replace(new RegExp("[^(a-zA-Zéèêà)]", "g"),'-').toLowerCase();
            // return r.replace(new RegExp("[^(a-zA-Zé\è\ê\à)]", "g"),'-').toLowerCase();
            return r;
        },
        postcomment(){
            this.loading = true;
            if(this.savedata){
                localStorage.setItem('districosite__comment__Oauth',JSON.stringify({
                    postc_user_email : this.postc_user_email,
                    postc_user_name : this.postc_user_name
                }));
            }
            backendService.postcomment({
                conseilc_user_email : this.commenter ? this.commenter.postc_user_email : this.postc_user_email,
                conseilc_user_name : this.commenter ? this.commenter.postc_user_name : this.postc_user_name,
                conseilc_content : this.postc_content,
                conseilc_idarticle : this.$route.params.id,
            }).then((response)=>{
                this.loading = false;
                if(response.status == 200){
                    alert('Commentaire enregistré avec succès !');
                    this.postc_user_email = null;
                    this.postc_user_name = null;
                    this.postc_content = null;
                    this.getcomment();
                }
            }).catch(()=>{
                this.loading = false;
            });
        },
        urlreplace(item){
            // this.article = true;
            // this.articlebug = false;
            this.$router.push({
                name : 'blogviewer' , params : {
                id : item.conseil_id,
                title : this.replace(item.conseil_title)
            }});
            // setTimeout(()=>{
                this.only();
            // },5000)
        },
        getcomment(){
            backendService.getcomment(this.$route.params.id).then((response)=>{
                if(response.status == 200){
                    this.comment = response.data.data;
                }
            });
        }
    }
}
</script>

<style>

</style>