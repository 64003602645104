<template>
  <div style="background:#eee">
  <div class="container pt-5 pb-5 mt-5">
        <div class="col-sm-12 p-3" style="min-height:400px;">
            <div class="row">
                <div class="col-sm-12">
                    <h2>Sur le blog de DISTRICOM</h2>
                </div>

                <div class="col-sm-12 mt-3">
                    <h5>Actualité</h5>
                    <p>Parcourez nos articles liés à notre actualité</p>
                    <div class="row">
                        <div class="col-sm-4 mb-3" v-for="(item) in actualites" :key="item.actualite_id">
                            <div class="box-blog shadow-sm bg-white">
                                <div style="width:100%;height:150px">
                                    <img style="object-fit:cover;width:100%;height:100%" :src="'https://apidistrico.districom-ci.net/public/picture/show/'+item.actualite_image" alt="">
                                </div>                                    
                                <div class="m-0 p-4 font-weight-bold text-ellipsis">{{item.actualite_title}}</div>
                                <p class="p-2 m-0">
                                    <router-link :to="'actualite/post/article/'+item.actualite_id+'/'+replace(item.actualite_title)"  class="btn btn-sm btn-site font-weight-bold">Lire l'article</router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 mt-3">
                    <h5>Astuces et conseils</h5>
                    <p>Parcourez nos articles liés aux astuces et conseils et apprennez à mieux vous preserver des incendies ...</p>
                    <div class="row">
                        <div class="col-sm-4 mb-3" v-for="(item) in data" :key="item.conseil_id">
                            <div class="box-blog shadow-sm bg-white">
                                <div style="width:100%;height:150px">
                                    <img style="object-fit:cover;width:100%;height:100%" :src="'https://apidistrico.districom-ci.net/public/picture/show/'+item.conseil_image" alt="">
                                </div>                                    
                                <div class="m-0 p-4 font-weight-bold text-ellipsis">{{item.conseil_title}}</div>
                                <p class="p-2 m-0">
                                    <router-link :to="'conseil/post/article/'+item.conseil_id+'/'+replace(item.conseil_title)"  class="btn btn-sm btn-site font-weight-bold">Lire l'article</router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
  </div>
</template>

<script>
import backendService from '../../backend.service';
export default {
    data(){
      return {
          data : [],
          actualites : []
      }
    },
    mounted(){

            backendService.conseil_public_get('shower').then((response)=>{
                if(response.status == 200){
                    this.data = response.data.data;
                }
            }).catch(()=>{});

            backendService.actualite_public_get('shower').then((response)=>{
                if(response.status == 200){
                    this.actualites = response.data.data;
                }
            }).catch(()=>{});
    },
    methods : {
        replace(info){
            let r = info.replace(/ /g,'-');
            r = info.replace(new RegExp("[^(a-zA-Zéèêà)]", "g"),'-').toLowerCase();
            return r;
        }
    }
}
</script>

<style>

.box-blog{
    width: 100%;
    min-height: auto;
    transition: all 0.5s ease-out;
}

.box-blog:hover{
    cursor: pointer;
    transform: scale(1.05);
    transition: all 0.5s ease-out;
}

</style>