<template>
    <div class="activity">
        <div class="col-sm-12 shadow-  border-bottom">
            <div class="row">
                <div class="col-sm-3 p-0" style="background:#db1917">
                    <p class="m-0 text-white p-3">
                        <b>Flash info</b>
                    </p>
                </div>
                <div class="col-sm-9">
                    <div class="swiper-container activity">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="item in news" :key="item.id">
                                <p class="m-0 p-3">
                                {{item.news}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="container">
        <div class="col-sm-12 mt-5">
            <h5 class="text-left text-black p-2 font-weight-bold" style="background:#eee"> Nos services et prestations</h5>
        </div>
         <div class="d-flex text-center" style="display:none" id="prestation-block-hidden">
                <div class="d-flex justify-content-around">
                    <div @click="active = 1" data-toggle="modal" data-target="#staticBackdrop">
                        <img src="../../assets/mascotte-pers.jpg" height="160" alt="">
                        <h6 class="mt-3">Distribution de Carburant</h6>
                    </div>
                    <div @click="active = 2" data-toggle="modal" data-target="#staticBackdrop">
                        <img src="../../assets/bout-ensemble.jpeg" height="160" alt="">
                        <h6 class="mt-3">Distribution de GAZ</h6>
                    </div>
                </div>
                <div class="d-flex justify-content-around">
                    <div @click="active = 3" data-toggle="modal" data-target="#staticBackdrop">
                        <img src="../../assets/camion-citerne-bleu.jpg" height="160" alt="">
                        <h6 class="mt-3">Transport d'hydrocarbure</h6>
                    </div>
                    <div @click="active = 4" data-toggle="modal" data-target="#staticBackdrop">
                        <!-- <router-link :to="{name : 'districombtp'}"></router-link> -->
                            <img src="../../assets/casque.jpg" height="160" alt="">
                            <h6 class="mt-3">BTP</h6>
                    </div>
                </div>
        </div>

        <div class="col-sm-12 p-3 text-center services" id="prestation-block-showed">
            <div class="row">
                <div class="col-sm-3 card-activity" @click="active = 1" data-toggle="modal" data-target="#staticBackdrop">
                    <img src="../../assets/mascotte-pers.jpg" height="140" alt="">
                    <h6 class="mt-3">Distribution de Carburant</h6>
                </div>
                <div class="col-sm-3 card-activity" @click="active = 2" data-toggle="modal" data-target="#staticBackdrop">
                        <img src="../../assets/bout-ensemble.jpeg" style="height:140px" alt="">
                        <h6 class="mt-3">Distribution de GAZ</h6>
                </div>
                <div class="col-sm-3 card-activity" @click="active = 3" data-toggle="modal" data-target="#staticBackdrop">
                        <img src="../../assets/camion-citerne-bleu.jpg" style="height:140px" alt="">
                        <h6 class="mt-3">Transport d'hydrocarbure</h6>
                </div>
                <div class="col-sm-3 card-activity" @click="active = 4" data-toggle="modal" data-target="#staticBackdrop">
                     <!-- <router-link :to="{name : 'districombtp'}"> -->
                        <img src="../../assets/casque.jpg" style="height:140px" alt="">
                        <h6 class="mt-3">BTP</h6>
                     <!--  -->
                </div>
            </div>
        </div>
        <div class="modal fade" id="staticBackdrop" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg  modal-dialog-scrollable">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">
                                <span v-html="
                                    active == 1 ? 'Distribution de Carburant' : active == 2 ?
                                    'Distribution de GAZ' : active == 3 ?
                                    'Transport d\'hydrocarbure' : 'BTP'"
                                ></span>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <DistricomOil v-if="active == 1 || active == 3"></DistricomOil>
                        <DistricomGaz v-if="active == 2"></DistricomGaz>
                        <DistricomBtp v-if="active == 4"></DistricomBtp>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
  import { Swiper, Navigation, Pagination,Autoplay, Scrollbar,A11y  } from 'swiper';
  import 'swiper/swiper-bundle.css';
import backendService from '../../backend.service';
  Swiper.use([Navigation, Pagination, Autoplay,Scrollbar,A11y ]);
  import DistricomGaz from '../layouts/activity/Districomgaz'
  import DistricomBtp from '../layouts/activity/Districombtp'
  import DistricomOil from '../layouts/activity/Districomoil'
export default {
  name: 'Activity',
  mixins : [],
  props: {},
  data(){
        return{
            news : [],
            active : 0
        }
    },
  components: {
    //   Swiper,
    //   SwiperSlide,
    DistricomGaz,
    DistricomBtp,
    DistricomOil
    },
    mounted() {
         backendService.news_get().then((response)=>{
            this.news = response.data.data.lastnews;
            setTimeout(()=>{
                new Swiper('.swiper-container.activity', {
                    // Optional parameters
                    autoplay: {
                        delay: 3000,
                    },
                    speed: 500,
                    slidesPerView: 1,
                    spaceBetween: 10,
                    direction: 'vertical',
                    loop: true,
                });
            },5000)
        })
  }
}
</script>

<style scoped lang="scss">
.test{
    border-top: 100px solid transparent;
    border-left: 100px solid  transparent;
    border-right: 100px solid  transparent;
    border-bottom: 100px solid  #000;

}
.card-activity:hover{
    cursor: pointer;
    color: blue;
}
    // .activity{
        /* background: #fff; */
    // }

    .services a{
        text-decoration : none !important; color: #000 !important
    }

    .box{
        min-height: 500px;
        width: 100%;
        background: #fff;
        color:#000;
    }
    .box:hover{
        /* border-bottom:5px solid #490; */
        cursor: pointer;
    }

    .box .img{
        height: 250px;
        width: 100%;
        display: block;
        background: #000;
    }

    .box-s{
        min-height: 300px;
        width: 100%;
        color:#FFF;
        background: #099548;
    }

    .box h1, .box-s h1{
          /* font-weight: 600; */
          font-size: 2rem;
    }

    .activity .img-activity{
        height: auto;
        width: 100%;
        background: #000;
    }

    // .img-dg{
        /* background:url('../../assets/msc_5251_2x.jpg') no-repeat scroll  50% 10% / 100% 150%; */
    // }

    .box img{
         width: 100%;
         height: 100%;
         object-fit: cover;
         vertical-align :middle;
    }

    
    .activity-describe{
        color:#FFF;
        background: #099548;
    }

    .swiper-container {
      width: 100%;
      height: 50px;

    }
    .swiper-slide {
      text-align: center;
      font-size: 14px;
      background: #fff;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }

</style>