<template>
      <div class="col-sm-12 p-0">
        <div class="footer">
          <div class="container pt-5 text-white">
            <div class="row">
              <div class="col-lg-12 pl-0 mb-5 text-center">
                    <img src="../../assets/logo-w.png" class="" height="60px" alt="">
                    <p class="mt-0 text-center">Ensemble pour une nouvelle aventure</p>
              </div>
              <!-- <div class="col-lg-9"></div> -->
              <div class="col-lg-3">
                <h4 class="text-yellow">Siège social</h4>
                <div>
                  <p class="mb-0 contact">Quartier Dioulakro, Sur la route d'Oumé</p>
                  <p class="mb-0 contact">Yamoussoukro, Cote d'Ivoire</p>
                  <p class="mb-0 contact">B.P 2487 Yamoussoukro Côte d'Ivoire</p>
                  <p class="mb-0 contact">Tél: +225 05 06 180 091</p>
                  <p class="mb-0 contact">Tél: +225 25 300 00 300</p>
                  <p class="mb-0 contact">Email: info@districom.ci</p>
                </div>
              </div>
              <div class="col-lg-3">
                <h4 class="text-yellow">A propos de nous !</h4>
                <p>Nous sommes une société ivoirienne créée en 2009 localisée à Yamoussoukro, présente aujourd’hui dans plus de 20 villes de la Côte d'Ivoire. Au cours des années, nous avons su nous adapter pour répondre à l’évolution des besoins d'hydrocarbure.</p>
              </div>
              <div class="col-lg-3">
                <h4 class="text-yellow">Newsletter</h4>
                <p>Abonnez-vous et soyez les premiers à être informés de notre actualité</p>
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-12 mt-2 p-0">
                        <input type="email" v-model="email" class="form-control" id="exampleInputEmail1" placeholder="Entrez votre adresse-email ici  " aria-describedby="emailHelp">
                        <div v-if="this.status > 0" class="alert mt-2" :class="status == 200 ? 'alert-success' : 'alert-warning'">
                          {{this.message}}
                        </div>
                        <button v-on:click="newsletters" :disabled="!email" style="width:auto" class="btn font-weight-bold btn-site m-0 mt-2">
                            Je m'abonne !
                            <div v-if="loading" class="spinner-border text-black ml-5 float-right" style="height:20px;width:20px" role="status">
                                    <span class="sr-only">Loading...</span>
                            </div>
                        </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <h4 class="text-yellow">Suivez-nous !</h4>
                <p>Suivez notre compagnie sur les réseaux sociaux !</p>
                 <div class="col-sm-12">
                    <div class="row mt-4">
                      <!-- <div class="flex"><img height="25" class="rounded-circle" src="../../assets/logo-linkedin-294x294.png" alt=""></div> -->
                      <div class="flex ml-2"><a href="https://facebook.com/DistricomYamoussoukro"><img height="25" class="rounded-circle" src="../../assets/facebook.png" alt=""></a></div>
                      <div class="flex ml-2"><a href="https://twitter.com/districom_ci"><img height="25" class="rounded-circle" src="../../assets/twitter.png" alt=""></a></div>
                      <div class="flex ml-2"><a href="https://www.instagram.com/districom_ci"><img height="25" class="rounded-circle" src="../../assets/instagram.png" alt=""></a></div>
                    </div>
                  </div>
              </div>
              <div class="col-lg-12 mt-5">
                <p>Copyright : © 2021 DISTRICOM sarl. Powered by <a target="_blank" href="https://www.linkedin.com/in/olivieradou/">Olivier ADOU</a></p>
              </div>
          </div>
        </div>
      </div>  
    </div>
</template>
<script>
import backendService from '../../backend.service';
export default {
  name: 'Footer',
  mixins :[],
  data(){
    return{
      email:null,
      loading : false,
      error : false, 
      status : 0,
      message : null
    }
  },
  methods:{
    newsletters(){
          this.loading = true;
          if(!this.email)
            return;
          backendService.newsletters({
            email: this.email
          }).then((response)=>{
            this.loading = false;
            this.message = response.data.message;
            this.status = response.data.status;
            setTimeout(()=>{
              this.status = 0;
            },3000)
          }).catch(()=>{
            this.loading = false;
          })
    }
  }
}
</script>

<style scoped>
    .footer{
        min-height: 450px;
        background: #191994;
    }
    .nav-link{
      margin-left: -14px;
    }
    .copyright {
      height: 30px;
      width: 100%;
      color: white;
      text-align: center;
      background-color: black;
    }
    .btn-btn{
      border: 2px solid #FF6600 !important;
      background: #FF6600 !important;
      color:#FFF !important;
      border-radius : 20px !important;
    }
    .footer .contact{
      color:white;
      font-size:15px;
    }
    .footer .rounded-circle{
      cursor: pointer;
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);

    }
    .footer .rounded-circle:hover{
      cursor: pointer;
      filter: none;
      -webkit-filter: none;
    }
</style>