<template>
        <div class="h-full d-flex">
            <div class="h-full-w400px">
                <div class="h-full-h85 p-4">
                        <p class="text-blue font-weight-bold">Ajouter un nouvel élément</p>
                        <input type="text" v-model="name" placeholder="Libellé" value="" class="mt-3 form-control">
                        <input type="text" v-model="price" placeholder="montant" class="mt-3 form-control">
                </div>
                <div class="h-full-h15 border-top p-2">
                    <button @click="create" :disabled="!name || !price" type="button" class="btn btn-md w-100 btn-site font-weight-bold">Ajouter
                         <div v-if="loading" class="spinner-border text-light float-right" style="height:20px;width:20px" role="status">
                                    <span class="sr-only">Loading...</span>
                         </div>
                    </button>
                </div>
            </div>
            <div class="h-full-rw400px border-left p-3">
                    <div class="d-flex justify-content-between">
                        <h1 class="text-blue">Liste des éléments</h1>
                        <div v-if="loadingget" class="spinner-border text-black mt-2" style="height:40px;width:40px" role="status">
                                <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <!-- {{data}} -->

                    <table class="table mt-3 table-bordered table-hover">
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Prix</th>
                            <th>Supprimer</th>
                        </tr>
                        </thead>
                        <tbody>
                                <tr v-for="item in data" :key="item.gazprice_id">
                                    <td>{{item.gazprice_name}}</td>
                                    <td>{{item.gazprice_price}}</td>
                                    <td>
                                        <button @click="deleted(item.gazprice_id)" class="btn btn-danger font-weight-bold btn-sm">Supprimer
                                          <div v-if="loadingdelete" class="spinner-border text-light float-right mt-2" style="height:20px;width:20px" role="status">
                                              <span class="sr-only">Loading...</span>
                                          </div>
                                        </button>
                                    </td>
                                </tr>
                        </tbody>
                    </table>
            </div>
        </div>
</template>

<script>
import backendService from '../../../backend.service'
export default {
    data(){
        return {
            loading : false,
            loadingget : false,
            name : null,
            price : null,
            data : []
        }
    },
    mounted(){
            this.get()
    },
    methods : {
        get(){
            this.loadingget = true
            backendService.gaz_get().then((response)=>{
                this.loadingget = false
                this.data = response.data.data;
            }).catch(()=>{this.loadingget = false})
        },
        create(){
            this.loading = true;
            backendService.gaz_create({
                gazprice_name : this.name,
                gazprice_price : this.price,
            }).then((response)=>{
                    this.loading = false;
                    this.data = response.data.data;
            }).catch(()=>{
                this.loading = false;
            })
        },
        deleted(id){
          this.loadingget = true
          backendService.gaz_delete(id).then(()=>{
            this.get()
            this.loadingget = false
          }).catch(()=>{this.loadingget = false})
        },
    }
}
</script>

<style>

</style>