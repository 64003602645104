<template>
  <div style="background: #eee">
    <div
      class="container pb-5 p-0"
      style="font-size: 14px; text-align: justify"
    >
      <div class="col-sm-12">
        <div class="row">
          <div
            class="col-sm-12 align-middle text-center p-5 prestation-title-page"
            style="background: #ffff00; height: 350px; font-size: 20px"
          >
            <!-- <h4>NOS PRODUITS ET SERVICES DISPONIBLES <br> DANS TOUTES LES STATIONS DE LA
            COMPAGNIE POUR LES PROFESSIONNELS.</h4> -->
          </div>

          <div class="col-sm-12" style="margin-top: -300px">
            <img
              src="../../../assets/Site-Districom-service-pro.jpg"
              class="img-fluid"
              alt=""
            />
            <div class="col-sm-12 shadow-sm mt-3 bg-white p-3">
              <p><span class="font-weight-bold p-2" style="background:#ffff00">Nos produits</span></p>
              <p class="font-weight-bold text-blue h6">Produits pétroliers et dérivés</p>
              <p>
                Chez DISTRICOM, le client est au centre de nos actions et
                constitue notre priorité. Nous souhaitons être votre partenaire
                privilégié en matière de produits pétroliers de votre
                entreprise, dans un cadre contractuel.
              </p>
              <p>
                C’est pourquoi, nous vous proposons une large gamme de produits
                pétroliers et dérivés pour tous vos besoins :
              </p>
              <p class="pl-3">
                – Super Sans Plomb <br />
                – Gasoil <br />
                – Pétrole Lampant <br />
                - DDO (Distillate Diesel Oil) <br />
                - Fuel oil <br />
                - Bitume <br />
                - Lubrifiants et graisse <br />
                - GAZ en vrac
              </p>
              <p>
                Nous proposons également du gaz butane en vrac à la demande pour
                vos besoins d’exploitation..
              </p>
              <p><span class="font-weight-bold p-2" style="background:#ffff00">Nos services</span></p>
              <p>
                Notre offre de services pour les professionnels comprennent :
              </p>
              <p class="pl-3">
                – Livraison sur site dans le respect des normes de sécurité
                <br />
                – Installation d’équipements de stockage et de distribution
                (cuve à gaz, citerne à carburant) <br />
                – Formation liée à la gestion d’un dépôt d’hydrocarbure <br />
                – Entretien et maintenance des installations
              </p>
              <p>
                Chez DISTRICOM, nous vous garantissons une qualité et une
                disponibilité constante de nos produits. Avec nous, vous avez
                l'assurance d'un caburant de qualité.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Swiper,
  Navigation,
  Pagination,
  Autoplay,
  Scrollbar,
  A11y,
} from "swiper";
import "swiper/swiper-bundle.css";
Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, A11y]);
export default {
  mounted() {
    setTimeout(() => {
      new Swiper(".swiper-container.stations", {
        // Optional parameters
        autoplay: {
          delay: 5000,
        },
        speed: 500,
        direction: "horizontal",
        loop: true,
        // If we need pagination
        pagination: {
          el: ".swiper-pagination",
        },

        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // And if we need scrollbar
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      });
    }, 2000);
  },
};
</script>

<style scoped lang="scss">
</style>