<template>
        <div class="h-full d-flex">
            <div class="h-full-w400px">
                <div class="h-full-h85 p-4">
                        <p class="text-blue font-weight-bold">Ajouter une nouvelle station</p>

                        <input type="text" v-model="name" placeholder="Nom de la station" value="DISTRICOM OIL-" class="mt-3 form-control">
                        <input type="text" v-model="ville" placeholder="ville" class="mt-3 form-control">
                        <input type="text" v-model="location" placeholder="Localisation" class="mt-3 form-control">
                        <input type="text" v-model="tel" placeholder="Téléphone" class="mt-3 form-control">
                        <input type="text" v-model="email" placeholder="Adresse-email" class="mt-3 form-control">
                        <input type="text" v-model="hour" placeholder="Horaire" class="mt-3 form-control">
                        <input type="text" v-model="service" placeholder="Services disponlibles" class="mt-3 form-control">
                        <input type="text" v-model="lat" placeholder="Latitude" class="mt-3 form-control">
                        <input type="text" v-model="lon" placeholder="Longitude" class="mt-3 mb-3 form-control">
                </div>
                <div class="h-full-h15 border-top p-2">
                    <button @click="create" :disabled="!name || !ville || !location || !tel || !email" type="button" class="btn btn-md w-100 btn-site font-weight-bold">Ajouter cette station
                         <div v-if="loading" class="spinner-border text-light float-right" style="height:20px;width:20px" role="status">
                                    <span class="sr-only">Loading...</span>
                         </div>
                    </button>
                </div>
            </div>
            <div class="h-full-rw400px border-left p-3">
                    <h1 class="text-blue">Liste des stations</h1>
                    <!-- {{data}} -->

                    <table class="table mt-3 table-bordered table-hover">
                        <thead>
                        <tr>
                            <th>station</th>
                            <th>ville</th>
                            <th>Localisation</th>
                            <th>Contact</th>
                            <th>Email</th>
                            <th>Horaire</th>
                            <th>Services</th>
                        </tr>
                        </thead>
                        <tbody>
                                <tr v-for="item in data.list" :key="item.id">
                                    <td>{{item.name}}</td>
                                    <td>{{item.ville}}</td>
                                    <td>{{item.location}}</td>
                                    <td>{{item.tel}}</td>
                                    <td>{{item.email}}</td>
                                    <td>{{item.hour}}</td>
                                    <td>{{item.services}}</td>
                                </tr>
                        </tbody>
                    </table>
            </div>
        </div>
</template>

<script>
import backendService from '../../../backend.service'
export default {
    data(){
        return {
            loading : false,
            name : null,
            ville : null,
            location : null,
            tel : null,
            email : null,
            hour : null,
            service : null,
            lat : null,
            lon : null,
            data : []
        }
    },
    mounted(){
            backendService.station_get().then((response)=>{
                    this.data = response.data.data;
            }).catch(()=>{})
    },
    methods : {
        create(){
            this.loading = true;
            backendService.station_create({
                name : this.name,
                ville : this.ville,
                location : this.location,
                tel : this.tel,
                email : this.email,
                hour : this.hour,
                services : this.service,
                lat : this.lat,
                lon : this.lon,
            }).then((response)=>{
                    this.loading = false;
                    this.data = response.data.data;
            }).catch(()=>{
                this.loading = false;
            })
        }
    }
}
</script>

<style>

</style>