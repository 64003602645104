<template>
          <div class="container mt-5">
          <div class="col-sm-12">
               <div class="dg">
                    <div class="col-sm-12">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="box img-dg">
                                            <img src="../../assets/Nos stations.jpg" style="width:100%;height:100%;object-fit:cover" alt="">
                                    </div>
                                </div>
                                <div class="col-sm-6 box-talking">
                                    <div class="box p-4">
                                        <div class="arrow-l"></div>
                                        <h1 class="mb-3">Mot du Directeur Général</h1> 
                                        <div>
                                            <p>Chers amis et partenaires, soyez la bienvenue sur le site de la société de distribution et de commerce DISTRICOM.</p>
                                            <p>DISTRICOM est une entreprise ivoirienne qui compte aujourd’hui dans le domaine des produits pétroliers en Cote d’Ivoire. Avec un réseau de stations-service en pleine expansion et deux centres emplisseurs de gaz, DISTRICOM assure la distribution régulière de produits pétroliers de qualité pour combler tous vos besoins énergétiques que vous soyez particuliers ou professionnels. </p> 
                                            <p class="m-0">DISTRICOM est aussi l’entreprise citoyenne par excellence avec une politique sociale reconnue qui se traduit à travers le don de 1 FCFA par litre aux orphelins à travers la fondation DISTRICOM.</p>
                                            <p>Vous trouverez sur ce site, des informations utiles sur nos produits et services ainsi que l'impact de nos activités sociales sur les communautés.</p>
                                            <p class="m-0"><b> <i> Hussein ATTAR</i></b> </p>
                                            <p class="m-0"><b> <i>    Directeur Général</i></b></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6 mt-5 border-lef">
                                    <div class="box p-4 box-talking-team" style="color:#000;background:#dfe1ed">
                                        <div class="arrow-r"></div>
                                        <h1 class="mb-3">Notre équipe</h1> 
                                        <p>
                                       Notre équipe de professionnels expérimentés et motivés, travaille sans relâche pour répondre à tous vos besoins. Les stations-services DISTRICOM sont ouvertes 24h/24 et 7j/7 dans le seul but de vous satisfaire à tout moment.
                                        </p> 
                                    </div>
                                </div>
                                <div class="col-sm-6 mt-5">
                                    <div class="box img-team" style="background:#FFF">
                                        <img src="../../assets/equipe_plan-de-travail2.jpg" style="object-fit:cover;width:100%;height:100%" alt="">
                                    </div>
                                </div>
                            </div>
                    </div>
               </div>
          </div>
          </div>
          
</template>
<script>
// import Swiper from 'swiper';
import $ from "jquery"
export default {
  name: 'Dg',
  mixins : [],
  props: {},
   data(){
        return{
            location : location.href
        }
    },
  mounted() {
        setTimeout(()=>{
            $('.img-dg').css({
                height : $('.box-talking').height()
            });

             $('.img-team').css({
                height : $('.box-talking-team').height()
            });
        },3000);
        // new Swiper ('.swiper-container.swiper-dg', {
        //     // spaceBetween: 30,
        //     centeredSlides: true,
        //     autoplay: {
        //         delay: 2500,
        //         disableOnInteraction: false,
        //     },
        //     pagination: {
        //         el: '.swiper-pagination',
        //         clickable: true,
        //     },
        //     navigation: {
        //         nextEl: '.swiper-button-next',
        //         prevEl: '.swiper-button-prev',
        //     },
        // });
  }
}
</script>

<style scoped>
    .box{
        min-height: 320px;
        width: 100%;
        background: #FFFF00;
        color:#000;
        position: relative;
    }

    .arrow-l{
        position: absolute;
        top:50px;
        left: -100px;
        border-top:50px solid transparent;
        border-bottom:50px solid transparent;
        border-right:50px solid #FFFF00;
        border-left:50px solid transparent;
    }

    .arrow-r{
        z-index: 1;
        position: absolute;
        top:50px;
        right : -100px;
        border-top:50px solid transparent;
        border-bottom:50px solid transparent;
        border-right:50px solid transparent;
        border-left:50px solid #dfe1ed;
    }
    .box:hover{
        /* border-bottom:5px solid #490; */
        cursor: pointer;
    }

    .box h1, .box-s h1{
          /* font-weight: 600;
          font-size: 2.5rem; */
    }
    .img-dg{
        background:url('../../assets/dg.jpg') no-repeat scroll #dfe1ed  100% 100% / 100% 100%;
    }

    .img-team{
        background:url('../../assets/equipe_plan-de-travail2.jpg') no-repeat scroll #dfe1ed  50% 10% / 100%;
    }
    .swiper-container {
      width: 100%;
      height: 420px;

    }
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
</style>