<template>
  <div class="container">
        <div class="col-sm-12 mb-5">
            <div class="row">
                
                <div class="col-sm-12 mt-5">
                    <h1 class="text-blue text-left">Ouvrez votre dépôt de gaz simplement et rapidement ! </h1>
                    <p>Liste des documents à fournir pour devenir distributeur DISTRICOM Gaz</p>
                    <p class="pl-3">
                        - Registre de commerce <br>
                        - Plan de localisation géographique du local <br>
                        - Photocopie de la CNI ou Attestation d'identité <br>
                        - Frais de dossier pour l’agrément auprès de la DGH à prévoir (Variable selon les zones) <br>
                        <br>
                    </p>
                    <h2 class="text-blue text-left">Soumettre vos documents en ligne</h2>
                    <hr>
                </div>

                <div class="col-sm-6">
                    <img src="../assets/panneau-publicitaire-ok.jpg" class="img-fluid">
                </div>

                <div class="col-sm-6 border-left">
                        <label for="">Nom & prénoms</label>
                        <input v-model="name" type="text" placeholder="Nom & prénoms" class="form-control">
                        <label for="" class="mt-3">Contact</label>
                        <input v-model="tel" type="number" placeholder="Téléphone" class="form-control">

                        <label for="" class="mt-4">Photocopie de votre pièce d'identité (PDF OBLIGATOIRE) <br>
                        CNI, passeport
                        </label>
                        <input id="cni" type="file" class="form-control">

                        <label for="" class="mt-4">Plan de localisation du local (PDF OBLIGATOIRE)</label>
                        <input id="plan" type="file" class="form-control">

                        <label for="" class="mt-4">Registre de commerce (RCCM) (PDF OBLIGATOIRE)</label>
                        <input id="rccm" type="file" class="form-control">
                        <div v-if="sending" class="alert alert-success mt-3">
                                Votre dossier a été envoyé avec success !
                        </div>
                        <button @click="opengaz" class="btn btn-site w-100 btn-lg font-weight-bold mt-5" data-toggle="modal" data-target="#candidature" style="width:auto">
                             Soumettre le dossier
                             <div v-if="loading" class="spinner-border text-light float-right mt-2" style="height:20px;width:20px" role="status">
                                    <span class="sr-only">Loading...</span>
                            </div>
                        </button>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import $ from 'jquery';
export default {
    data(){
        return {
            name : null,
            tel : null,
            cni : null,
            plan : null,
            rccm : null,
            sending : false,
            loading : false
        }
    },
    methods : {
        opengaz(){
              this.loading = true;
              let me = this;
              var fd = new FormData();
              fd.append('name',me.name);
              fd.append('tel',me.tel);
              fd.append('cni',$('#cni')[0].files[0]);
              fd.append('plan',$('#plan')[0].files[0]);
              fd.append('rccm',$('#rccm')[0].files[0]);
              $.ajax({
                  url: 'https://apidistrico.districom-ci.net/public/opengazshop/apply',
                  type: 'post',
                  data: fd,
                  contentType: false,
                  processData: false,
                  success: function(response){
                      me.loading = false;
                      if(response.status == 200){
                          me.sending = true;
                          setTimeout(()=>{
                              location.reload(true);
                          },5000)
                      }
                  },
              });
        }
    }
}
</script>

<style>

</style>