<template>
        <div class="p-0" style="text-align : justify">
            <p class="border-bottom"><img class="w-100" src="../../../assets/btp.jpg" alt=""></p>
            <div class="p-3">
                <h6 class="text-blue font-weight-bold">Confiez vos projets de construction de station-service à DISTRICOM. </h6>
                <p>Notre expérience de plus de 20 ans dans la construction de stations-services constitue pour vous, une assurance pour nous confier vos projets de construction de station-service à DISTRICOM, d’installation d’équipement de stockage et de distribution de carburant ou gaz.</p>
            </div>
        </div>
</template>

<script>
export default {
name: 'Districombtp',
}
</script>

<style>

</style>