<template>
    <div class="partenaire pb-5" style="background:#0b2239">
        <div class="p-3">
            <div class="col-sm-12 text-center mt-5">
                <h1 class="text-white font-weight-bold">Nos partenaires</h1>
                <p class="text-white">Ils nous font confiance</p>
            </div>
            <!-- <div class="container"> -->
                <!-- <div class="col-sm-12">
                    <div class="row">
                         <div class="col-sm-3">
                                <div class="swiper-slide">
                        <a  href="http://www.gestoci.ci" target="_blank"> <img src="https://districom.ci/images/2020/07/05/24-021.png" height="60" alt=""></a> 
                                </div>
                        </div>
                         <div class="col-sm-3">
                                <div class="swiper-slide">
                        <a  href="http://www.smb.ci" target="_blank"> <img src="https://districom.ci/images/2020/07/05/smb-02.png" height="60" alt=""></a> 
                                </div>
                        </div>
                         <div class="col-sm-3">
                                <div class="swiper-slide">
                         <a  href="http://www.petroci.ci" target="_blank"> <img src="https://districom.ci/images/2020/07/05/petro-02.png" height="60" alt=""></a> 
                                </div>
                        </div>

                         <div class="col-sm-3">
                                <div class="swiper-slide">
                         <a  href="http://www.presticom-ci.com" target="_blank"> <img src="../../assets/presticom.png" height="60" alt=""></a> 
                                </div>
                        </div>
                         <div class="col-sm-3">
                                <div class="swiper-slide">
                                    <a  href="http://www.groupesoroubat.com" target="_blank"> <img src="../../assets/logo_soroubat.png" height="60" alt=""></a> 
                                </div>
                        </div>

                        <div class="col-sm-3">
                                <div class="swiper-slide">
                                    <a  href="http://www.dgh.ci" target="_blank"> <img src="../../assets/logo-dgh.png" height="60" alt=""></a> 
                                </div>
                        </div>
                        <div class="col-sm-3">
                                <div class="swiper-slide">
                                    <a  href="http://www.sudcontractors.com" target="_blank"> <img src="../../assets/logo_sudcontractors.png" height="60" alt=""></a> 
                                </div>
                        </div>
                        <div class="col-sm-3">
                                <div class="swiper-slide">
                                    <a  href="http://www.cocitam.com" target="_blank"> <img src="../../assets/logo_cocitam.png" height="60" alt=""></a> 
                                </div>
                        </div>
                        <div class="col-sm-3">
                                <div class="swiper-slide">
                                    <a  href="http://www.globalservicesci.com" target="_blank"> <img src="../../assets/logo-global.jpg" height="60" alt=""></a> 
                                </div>
                        </div>
                        <div class="col-sm-3">
                                <div class="swiper-slide">
                                    <a  href="http://www.mpeder.ci" target="_blank"> <img src="../../assets/mine.jpg" height="60" alt=""></a> 
                                </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="swiper-container partner">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <!-- http://www.presticom-ci.com -->
                         <a  href="#" target="_blank"> <img src="../../assets/presticom.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                        <!-- http://www.mtn.ci -->
                         <a  href="#" target="_blank"> <img src="../../assets/mtn-logo.jpg" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                        <!-- http://www.banqueatlantique.net -->
                         <a  href="#" target="_blank"> <img src="../../assets/ba.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                        <!-- http://www.sib.ci -->
                         <a  href="#" target="_blank"> <img src="../../assets/sib.jpg" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                        <!-- http://www.banquebni.net -->
                         <a  href="#" target="_blank"> <img src="../../assets/bni.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                        <!-- http://www.groupensia.com -->
                         <a  href="#" target="_blank"> <img src="../../assets/NSIA-Banque.jpg" height="60" alt=""></a> 
                    </div>
                     <div class="swiper-slide">
                         <!-- http://www.groupensia.com -->
                         <a  href="#" target="_blank"> <img src="../../assets/NSIA-Assurance-Banque.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                        <!-- http://www.sahamassurance.ci -->
                         <a  href="#" target="_blank"> <img src="../../assets/saham.jpg" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                        <!-- http://www.brinks.fr -->
                         <a  href="#" target="_blank"> <img src="../../assets/brinks.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                        <!-- http://www.cnps.ci -->
                         <a  href="#" target="_blank"> <img src="../../assets/cnps.jpeg" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                        <!-- http://agenceemploijeunes.ci -->
                         <a  href="#" target="_blank"> <img src="../../assets/Logo-AEJ.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                        <!-- http://www.banquepopulaire.ci -->
                         <a  href="#" target="_blank"> <img src="../../assets/bp.png" height="60" alt=""></a> 
                    </div>
                    <!-- <div class="swiper-slide">
                         <a  href="http://www.cnps.ci" target="_blank"> <img src="../../assets/cnps.jpg" height="60" alt=""></a> 
                    </div> -->
                    <div class="swiper-slide">
                        <!-- http://www.societegenerale.ci -->
                         <a  href="#" target="_blank"> <img src="../../assets/sgbci.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                        <!-- http://www.groupesoroubat.com -->
                        <a  href="#" target="_blank"> <img src="../../assets/logo_soroubat.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                        <!-- http://www.dgh.ci -->
                                    <a  href="#" target="_blank"> <img src="../../assets/logo-dgh.png" height="60" alt=""></a> 
                                </div>
                    <div class="swiper-slide">
                        <!-- http://www.sudcontractors.com -->
                                    <a  href="#" target="_blank"> <img src="../../assets/logo_sudcontractors.png" height="60" alt=""></a> 
                                </div>
                    <div class="swiper-slide">
                        <!-- http://www.globalservicesci.com -->
                                    <a  href="#" target="_blank"> <img src="../../assets/logo-global.jpg" height="60" alt=""></a> 
                                </div>
                    <div class="swiper-slide">
                        <!-- http://www.gestoci.ci -->
                        <a  href="#" target="_blank"> <img src="../../assets/gestoci.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                        <!-- http://www.smb.ci -->
                        <a  href="#" target="_blank"> <img src="../../assets/smb.jpg" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                        <!-- http://www.petroci.ci -->
                            <a  href="#" target="_blank"> <img src="../../assets/petroci.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                        <!-- http://www.mpeder.ci -->
                        <a  href="#" target="_blank"> <img src="../../assets/mine.jpg" height="60" alt=""></a> 
                    </div>
                    
                </div>

                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { Swiper, Navigation, Pagination,Autoplay, Scrollbar } from 'swiper';
import 'swiper/swiper-bundle.css';
Swiper.use([Navigation, Pagination,Autoplay, Scrollbar]);
export default {
    name:'Partenaire',
    mixins : [],
    data(){
        return {
            init : {
                // Optional parameters
                autoplay: {
                    delay: 5000,
                },
                speed: 500,
                slidesPerView: 1,
                slidesPerColumn: 2,
                spaceBetween: 10,
                direction: 'horizontal',
                loop: true,

                // If we need pagination
                pagination: {
                    el: '.swiper-pagination'
                },

                // Navigation arrows
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },

                // And if we need scrollbar
                scrollbar: {
                    el: '.swiper-scrollbar',
                },
            }
        }
    } ,
    mounted() {
        if(window.screen.width >= 768){
            this.init.slidesPerView = 2;
        }

        if(window.screen.width >= 992){
            this.init.slidesPerView = 4;
        }
        new Swiper('.swiper-container.partner', this.init);
    }
}
</script>
<style scoped>
    .swiper-container {
      width: 100%;
      height: 200px;
      margin-left: auto;
      margin-right: auto;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      height: calc((100% - 30px) / 2);

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
</style>