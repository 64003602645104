<template>
  <div class="col-sm-12 pb-5" style="padding-top:50px;background:linear-gradient(#f1f3f6, #f1f3f6);" v-if="data">
    <div class="row">
        <div class="col-sm-12">
            <div class="alert alert-success" v-if="article">
                <div class="d-flex">
                    <div class="spinner-border mr-5" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                    <span>Patientez pendant le chargement de l'article ...</span>
                </div>
            </div>
            <div class="alert alert-danger" v-if="articlebug">
                Nous n'arrivons pas à nous connecter au serveur, merci de verifier votre connexion internet .. 
            </div>
            <div class="row" v-if="(!article && !articlebug) || (data && data.name)">
                <div class="col-sm-8">
                    <div class="col-sm-12 bg-white p-3">
                        <div class="d-flex justify-content-between">
                            <p class="m-0">Publié le : {{data.created_at}}</p>
                            <p class="m-0">{{comment.length}} commentaire(s)</p>
                        </div>
                        <h1 class="text-orange mb-3">{{data.actualite_title}}</h1>
                        <div class="col-sm-12 pl-0 mt-3">
                            <ul class="list-group list-group-horizontal" v-if="data && data.actualite_title" style="cursor : pointer">
                                <li class="list-group-item flex-fill">
                                    <ShareNetwork
                                        network="facebook"
                                        :url="'https://districom.ci'+$route.path"
                                        :title="data.actualite_title"
                                        :description="data.actualite_title"
                                        :quote="data.actualite_title"
                                    >
                                    <img src="../../assets/facebook-f.png" style="width:20px" alt=""> Facebook
                                    </ShareNetwork>
                                </li>
                                <li class="list-group-item flex-fill">
                                    <ShareNetwork
                                        network="twitter"
                                        :url="'https://districom.ci'+$route.path"
                                        :title="data.actualite_title"
                                    >
                                    <img src="../../assets/twitter.png" style="width:20px" alt=""> Twitter
                                    </ShareNetwork>
                                </li>   
                                <li class="list-group-item flex-fill">
                                    <ShareNetwork
                                        network="linkedin"
                                        :url="'https://districom.ci'+$route.path"
                                    >
                                    <img src="../../assets/logo-linkedin-294x294.png" style="width:20px" alt=""> LinkedIn
                                    </ShareNetwork>
                                </li>   
                                <li class="list-group-item flex-fill">
                                    <ShareNetwork
                                        network="whatsapp"
                                        :url="'https://districom.ci'+$route.path"
                                        :title="data.actualite_title"
                                        :description="data.actualite_title"
                                    >
                                    <img src="../../assets/whatsapp.png" style="width:20px" alt=""> Whatsapp
                                    </ShareNetwork>
                                </li> 
                                <li class="list-group-item flex-fill">
                                    <ShareNetwork
                                        network="email"
                                        :url="'https://districom.ci'+$route.path"
                                        :title="data.actualite_title"
                                        :description="data.actualite_title"
                                    >
                                    <img src="../../assets/email.png" style="width:20px" alt=""> Email
                                    </ShareNetwork>
                                </li>   
                            </ul>
                        </div>
                        <div class="coverpicture mt-5" style="height:400px;width:100%">
                            <img style="object-fit : cover;height:100%; width:100%" :src="'https://apidistrico.districom-ci.net/public/picture/show/'+data.actualite_image" alt="">
                        </div>
                        <div v-html="data.actualite_content" class="mt-5"></div>

                        <div class="col-sm-12 mt-5" v-if="comment.length > 0">
                            <div class="media mt-3" v-for="(cm) in comment" :key="cm.postc_id">
                                <img src="../../assets/usernoavatar.png" style="height:40px" class="align-self-start mr-3" alt="...">
                                <div class="media-body">
                                    <h6 class="mt-0">{{cm.actualitec_user_name}} <br> <span style="font-size:12px" class="pull-right"><i>{{cm.created_at}}</i></span></h6>
                                    <p>{{cm.actualitec_content}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 mt-5 p-3 border" style="background:#F1F2F3">
                            <h4 class="text-orange">Laissez un commentaire.</h4>
                            <p>Nous seront ravis de savoir vos impressions concernant nos articles</p>
                            <div v-if="!commenter">
                                <input type="email" v-model="postc_user_email" class="form-control" placeholder="Adresse email">
                                <input type="text" v-model="postc_user_name" class="form-control mt-3" placeholder="Nom complet">
                            </div>
                            <div v-if="commenter" class="media">
                                <img src="../../assets/usernoavatar.png" style="height:40px" class="align-self-start mr-3" alt="...">
                                <div class="media-body">
                                    <h5 class="mt-0">{{commenter.postc_user_name}}</h5>
                                    <p>{{commenter.postc_user_email}}</p>
                                </div>
                            </div>
                            <textarea name="" v-model="postc_content" id="" cols="30" rows="5" class="form-control mt-3" placeholder="Laissez votre commmentaire ici ..."></textarea>
                            <div v-if="!commenter" class="custom-control custom-checkbox mt-3" style="font-size:12px">
                                <input v-model="savedata" type="checkbox" class="custom-control-input" id="customCheck1">
                                <label class="custom-control-label" for="customCheck1">Voulez vous que nous enregistrons vos informations dans le navigateur, pour ne plus avoir à saisir votre email et votre nom</label>
                            </div>
                            <button @click="postcomment" :disabled="!commenter && (!postc_user_email || !postc_user_name) || !postc_content || loading" class="btn btn-site mt-3 font-weight-bold">Commentez
                                <div v-if="loading" class="spinner-border text-light float-right mt-2" style="height:20px;width:20px" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4" v-if="simular.length > 0">
                    <div class="col-sm-12 p-3 bg-white">
                        <div class="row">
                            <div class="col-sm-12">
                                <h6 class="text-orange">Publications récentes</h6>
                            </div>
                            <div class="col-sm-12 mt-3" v-for="(item) in simular" :key="item.actualite_id">
                                <div class="cadre align-items-center d-flex border rounded-lg p-0">
                                    <div style="width:auto;height:100px">
                                        <img style="object-fit:cover;width:100%;height:100%" :src="'https://apidistrico.districom-ci.net/public/picture/show/'+item.actualite_image" alt="">
                                    </div>                            
                                    <div>
                                        <p class="m-0 p-3">{{item.actualite_title}}</p>
                                        <p class="m-0 p-2">
                                            <button @click="urlreplace(item)" class="btn btn-site font-weight-bold btn-sm">Lire l'article</button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import backendService from '../../backend.service'
export default {
    data(){
      return {
          data : {},
          simular : [],
          postc_user_email : null,
          postc_user_name : null,
          postc_content : null,
          savedata : false,
          article : false,
          articlebug : false,
          comment : [],
          loading : false,
          commenter : JSON.parse(localStorage.getItem('districosite__comment__Oauth')),
      }
    },
    created(){
    },
    mounted(){
        this.only();
    },
    updated(){
       
    },
    computed : {    
        watchUrlChange(){
            return this.$route.path;
        }
    },
    methods : {
        only(){
                this.article = true;
                this.articlebug = false;
                backendService.actualite_public_get(this.$route.params.id).then((response)=>{
                    if(response.status == 200){
                        this.data = response.data.data;
                        document.title = this.data.actualite_title;
                        document.querySelector('meta[property="og:description"]').setAttribute('content',this.data.actualite_content)
                        document.querySelector('meta[property="og:url"]').setAttribute('content','https://districom.ci'+this.$route.path)
                        document.querySelector('meta[property="og:title"]').setAttribute('content',this.data.actualite_title)
                        document.querySelector('meta[property="og:image"]').setAttribute('content',"https://apidistrico.districom-ci.net/public/picture/show/"+this.data.actualite_image)
                        this.article = false;
                        this.articlebug = false;
                    }
                }).catch(()=>{
                    this.article = false;
                    this.articlebug = true;
                });

                 backendService.actualite_getsimular(this.$route.params.id).then((response)=>{
                    if(response.status == 200){
                        this.simular = response.data.data;
                    }
                });

                this.getcomment();
        },
        whatsapp(){
            window.location="https://wa.me/?text="+encodeURIComponent(window.location.href);
        },
        replace(info){
            let r = info.replace(/ /g,'-');
            r = info.replace(new RegExp("[^(a-zA-Zéèêà)]", "g"),'-').toLowerCase();
            // return r.replace(new RegExp("[^(a-zA-Zé\è\ê\à)]", "g"),'-').toLowerCase();
            return r;
        },
        postcomment(){
            this.loading = true;
            if(this.savedata){
                localStorage.setItem('districosite__comment__Oauth',JSON.stringify({
                    postc_user_email : this.postc_user_email,
                    postc_user_name : this.postc_user_name
                }));
            }
            backendService.actualite_postcomment({
                actualitec_user_email : this.commenter ? this.commenter.postc_user_email : this.postc_user_email,
                actualitec_user_name : this.commenter ? this.commenter.postc_user_name : this.postc_user_name,
                actualitec_content : this.postc_content,
                actualitec_idarticle : this.$route.params.id,
            }).then((response)=>{
                this.loading = false;
                if(response.status == 200){
                    alert('Commentaire enregistré avec succès !');
                    this.postc_user_email = null;
                    this.postc_user_name = null;
                    this.postc_content = null;
                    this.getcomment();
                }
            }).catch(()=>{
                this.loading = false;
            });
        },
        urlreplace(item){
            // this.article = true;
            // this.articlebug = false;
            this.$router.push({
                name : 'actualiteviewer' , params : {
                id : item.actualite_id,
                title : this.replace(item.actualite_title)
            }});
            // setTimeout(()=>{
                this.only();
            // },5000)
        },
        getcomment(){
            backendService.actualite_getcomment(this.$route.params.id).then((response)=>{
                if(response.status == 200){
                    this.comment = response.data.data;
                }
            });
        }
    }
}
</script>

<style>

</style>