<template>
          <div class="mt-5 p-3" style="background:#0b2239">
          <h4 class="text-center implantation-title text-white mt-5">Nous sommes présents dans <b>{{cities.city.length}}</b> villes de la Côte d'Ivoire, <br> avec plus de <b>{{cities.list.length}}</b> stations ...</h4>
           <div class="swiper-container imp"> 
                <div class="swiper-wrapper">                                          
                    <div class="swiper-slide" v-for="item in cities.city" :key="item.id">
                        <h1 id="implantation-title-city" style="color:#F00; text-transform : uppercase">{{item.ville}}</h1>
                    </div>
                </div>
            </div>
          <div class="col-sm-12 p-0 mt-3">
               <div class="dg shadow-sm">
                    <div class="col-sm-12 p-0">
                        <div class="box implantation-maps" style="height:600px">
                            <GmapMap
                                :options="{
                                    zoomControl: true,
                                    mapTypeControl: true,
                                    scaleControl: true,
                                    streetViewControl: true,
                                    rotateControl: true,
                                    fullscreenControl: true,
                                    disableDefaultUi: false
                                }"
                                :center="{
                                    lat: 6.7960670,
                                    lng: -5.2794310
                                }"
                                id="implantation-Gmaps"
                                style="width: 100%; height: 600px"
                                    :zoom="7"
                                >
                                <GmapMarker
                                    :key="index"
                                    v-for="(m, index) in markers"
                                    :position="m.position"
                                    :clickable="true"
                                    :draggable="true"
                                    @click="center=m.position"
                                    :icon="markerOptions"
                                />
                            </GmapMap>
                    </div>
               </div>
            </div>
          </div>
    </div>
</template>
<script>
import { Swiper, Navigation, Pagination,Autoplay, Scrollbar } from 'swiper';
import 'swiper/swiper-bundle.css';
import backendService from '../../backend.service';
Swiper.use([Navigation, Pagination,Autoplay, Scrollbar]);
const mapMarker = require('../../assets/clipart2350691.png');
export default {
  name: 'Implantation',
  mixins : [],
  props: {},
   data(){
        return{
            location : location.href,
            cities : [],
            markers : [{
            position : {
                lat: 6.7960670,
                lng: -5.2794310
                }
            }],
            markerOptions: {
                url: mapMarker,
                size: {width: 50, height: 60, f: 'px', b: 'px',},
                scaledSize: {width: 30, height: 45, f: 'px', b: 'px',},
            }
        }
    },
  mounted() {
        // select city where there are stations .... 
        backendService.station_get().then((response)=>{
            this.cities = response.data.data;
            this.cities.list.forEach(element => {
                this.markers.push({
                  "position" : {
                    lat : parseFloat(element.lat),
                    lng : parseFloat(element.lon),
                  }
                })
            });
            setTimeout(()=>{
                new Swiper('.swiper-container.imp', {
                    // Optional parameters
                    autoplay: {
                        delay: 3000,
                    },
                    speed: 500,
                    slidesPerView: 1,
                    spaceBetween: 10,
                    direction: 'vertical',
                    loop: true,
                });
            },5000)
        }).catch(()=>{});

        
  }
}
</script>

<style scoped>
    .box{
        min-height: 320px;
        width: 100%;
        background: #FFF;
        color:#000;
    }
    .box:hover{
        /* border-bottom:5px solid #490; */
        cursor: pointer;
    }

    .box h1, .box-s h1{
          /* font-weight: 600;
          font-size: 2.5rem; */
    }
    .img-carte{
        background:url('../../assets/carte.png') no-repeat scroll #dfe1ed  50% 10% / 100%;
    }

    .swiper-container {
      width: 100%;
      height: 100px;
    }
    .swiper-slide {
      text-align: center;
      /* background: #fff; */

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
    
</style>